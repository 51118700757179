import React, { useContext, useEffect, useState } from "react";
import AuthService from "../../utils/services/auth-service";
import { useHistory } from "react-router-dom";
import Routes from "../../routes/routes";
import "./header.css";
import { SampleContext } from "../../contexts/SampleContext";
import Api2 from "../../utils/api2";
import { useAdmitStatus } from "../../apis/AdmitAPI";


const Header = ({ tenant }) => {
  let history = useHistory();
  const [patientData, setPatientData] = useState()

  const { data: allAdmitStatusList } = useAdmitStatus();

  useEffect(() => {
    const UserString = localStorage.getItem("USER");
    const userObject = JSON.parse(UserString);

    const patientSlug = userObject?.slug;

    // Only get patient admit data when logged in
    if (patientSlug) {
      Api2.get(`/admit/patient/${patientSlug}`).subscribe((resp) => {
        if (resp) {
          console.log("test_rooms", resp?.data?.data);

          setPatientData(resp?.data?.data);
        }
      });
    }
  }, []);


  const clickLogin = () => {
    history.push(Routes.LOGIN);
  };

  const clickLog = () => {
    localStorage.removeItem("usersdatatoken")
    localStorage.removeItem("userName")
    history.push(Routes.LOGOUT);
  };

  const clickChat = () => {
    history.push(Routes.CHAT);
  };

  const clickAppointment = () => {
    history.push(Routes.APPOINTMENT_LIST);
  };

  const clickRoom = () => {
    history.push(Routes.ROOM)
  }

  const clickReport = () => {
    history.push(Routes.REPORT);
  };

  const clickProfile = () => {
    history.push(Routes.PROFILE);
  };

  const { back3, back4, back1, back2 } = useContext(SampleContext)

  var back5 = {};
  back5.cursor = "pointer";
  back5.color = back2.color;
  back5.backgroundColor = back2.backgroundColor;


  String.prototype.convertToRGB = function () {

    var aRgbHex = this.match(/.{1,2}/g);
    var aRgb = [
      parseInt(aRgbHex[0], 16),
      parseInt(aRgbHex[1], 16),
      parseInt(aRgbHex[2], 16),
      parseFloat('0.5', 2)
    ];
    return aRgb;
  }

  const colorcode = back2.backgroundColor.replace('#', '')
  const colorc = colorcode.convertToRGB();
  const code = colorc.join();

  return (
    <>
      <div className="container">

        <nav className="container-fluid col-md-12 mx-auto navbar d-flex justify-content-evenly mb-4 fixed-top pt-0" style={{ color: back3?.color, backgroundColor: `rgba(${code})` }}>
          <div className="container p-2 pb-0" >
            <a href="/" className="ps-3">
              <img
                style={{
                  width: "100%",
                  maxWidth: "60px"
                }}
                src={tenant?.logo}
                alt=""
              />
            </a>
            <div>
              <button
                className="navbar-toggler toggle_button"
                type="button"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasNavbar"
                aria-controls="offcanvasNavbar" style={back3}
              >
                <i class="fa-solid fa-bars" style={{ color: back2.color }}></i>
              </button>
            </div>
          </div>
        </nav>
        <div
          className="offcanvas offcanvas-end p-0"
          tabindex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          style={{ zIndex: "999999999" }}
        >
          <div className="offcanvas-header" style={back2}>
            {/* <button
            style={back2}
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close" 
          ></button> */}
            <i class="fa fa-times fs-2 pe-auto" data-bs-dismiss="offcanvas" style={{ cursor: "pointer" }}
              aria-label="Close" ></i>
          </div>
          <div className="offcanvas-body" style={back2}>
            <div className="container">
              <ul className="nav flex-column">
                {!AuthService.isAuthenticated && (
                  <li className="nav-item">
                    <div
                      className="nav-link"
                      aria-current="page"
                      onClick={clickLogin}
                      data-bs-dismiss="offcanvas"
                      style={back5}
                    >
                      <i
                        className="fa fa-sign-in"
                        style={{ color: "gray", "margin-right": "12px", cursor: "pointer" }}
                        aria-hidden="true"
                      ></i>
                      Login
                    </div>
                  </li>
                )}

                {AuthService.isAuthenticated && (
                  <>

                    {/* COMMENTED as it was falling back to appointment only, no functionality linked to it. */}
                    {/* <li className="nav-item py-1">
                      <div className="nav-link " onClick={clickAppointment} data-bs-dismiss="offcanvas" style={back5}>
                        <img
                          src="/images/Vector1.png"
                          className="img-fluid px-2"
                          alt="image"
                        />
                        Prescriptions
                      </div>
                    </li> */}

                    <li className="nav-item py-1">
                      <div className="nav-link " onClick={clickReport} data-bs-dismiss="offcanvas" style={back5}>
                        <img
                          src="/images/report.png"
                          className="img-fluid px-2"
                          alt=""
                          style={{ cursor: "pointer" }}
                        />
                        Reports
                      </div>
                    </li>
                    <li className="nav-item active py-1">
                      <div className="nav-link " onClick={clickAppointment} data-bs-dismiss="offcanvas" style={back5}>
                        <img
                          src="/images/appoint.png"
                          className="img-fluid px-2"
                          alt=""
                          style={{ cursor: "pointer" }}
                        />
                        My Appointments
                      </div>
                    </li>

                    <li className="nav-item py-1">
                      <div className="nav-link " onClick={clickProfile} data-bs-dismiss="offcanvas" style={back5}>
                        <img
                          src="/images/Group.png"
                          className="img-fluid px-2"
                          alt=""
                          style={back5}
                        />
                        My Profile
                      </div>
                    </li>
                    {
                      checkPatientAdmittedStatus(patientData?.status, allAdmitStatusList) ? (
                        <li className="nav-item py-1">
                          <div className="nav-link " onClick={clickRoom} data-bs-dismiss="offcanvas" style={back5}>
                            <img
                              src="/images/Group.png"
                              className="img-fluid px-2"
                              alt="image"
                              style={back5}
                            />
                            Room
                          </div>
                        </li>
                      ) : ("")
                    }

                    <li className="nav-item py-1">
                      <div className="nav-link" onClick={clickLog} data-bs-dismiss="offcanvas" style={back5}>
                        <img
                          src="/images/log.png"
                          className="img-fluid px-2"
                          alt="image"
                          style={{ cursor: "pointer" }}
                        />
                        Logout
                      </div>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        </div>


      </div>
    </>
  );
};

const checkPatientAdmittedStatus = (patientCurrentStatus, allAdmitStatusList) => {
  return allAdmitStatusList?.some(status => status === patientCurrentStatus);
}

export default Header;
